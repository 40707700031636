import React from "react";
import { Link } from "react-router-dom";
import Banners from "./Banners";

function Empresa() {
  return (
    <section>
      <Banners />
      <h1>Metalúrgica FABÁL.</h1>
      <p>
        Metalúrgica FABÁL es una empresa Uruguaya especializada en la prestación
        de servicios metalúrgicos a empresas en todo el territorio Nacional.
      </p>
      <p>
        FABÁL reúne profesionales con larga trayectoria y experiencia en este
        rubro, hoy contamos con soldadores calificados, cañistas, oficiales
        especializados en montajes, etc. Realizando trabajos principalmente en
        la industria.
      </p>
      <p>
        Le invitamos a <Link to="/servicios/">conocer nuestros servicios</Link>,
        <Link to="/galeria/">
          ver fotografias de nuestros trabajos realizados
        </Link>
        , <Link to="/clientes/">informarse sobre nuestros clientes</Link> y
        <Link to="/contacto/"> contactarse con nosotros</Link> cuando lo desee.
      </p>
      <p>Gracias por visitarnos.</p>
    </section>
  );
}

export default Empresa;
