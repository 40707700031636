import React from 'react';
import styled from 'styled-components';

import lasMoras from '../Images/clientes/las-moras.jpg';
import inaler from '../Images/clientes/inaler.jpg';
import tresArroyos from '../Images/clientes/tres-arroyos.jpg';
import clay from '../Images/clientes/clay.jpg';
import salto from '../Images/clientes/salto.jpg';
import santaClara from '../Images/clientes/santa-clara.jpg';
import carrasco from '../Images/clientes/carrasco.jpg';
import pul from '../Images/clientes/pul.jpg';
import copayan from '../Images/clientes/copayan.jpg';
import canelones from '../Images/clientes/canelones.jpg';

const ListaDeclientes = styled.ul`
  li {
    display: inline-block;
    width: 31%;
    margin: 1%;

    @media (max-width: 425px) {
      width: 46%;
      margin: 2%;
    }
    vertical-align: top;
    img {
      width: 100%;
      border-radius: 3px;
      border: 1px solid ${props => props.theme.colorPrincipal};
    }
    span {
      display: block;
      font-size: 0.9rem;
      font-weight: 400;
      text-align: center;
    }
  }
`;

function Clientes() {
  return (
    <section>
      <h2>Nuestros Clientes.</h2>
      <p>
        A continuación aglunos de los clientes que han confiando en nosotros.
      </p>
      <ListaDeclientes>
        <li>
          <img src={lasMoras} alt="Frigorífico Las Moras" />
          <span>Frigorífico Las Moras – Canelones</span>
        </li>
        <li>
          <img src={inaler} alt="Frigorífico Inaler" />
          <span>Frigorífico Inaler – San José</span>
        </li>
        <li>
          <img src={tresArroyos} alt="Granja Tres Arroyos" />
          <span>Granja Tres Arroyos – Montevideo</span>
        </li>
        <li>
          <img src={clay} alt="Frigorífico Clay" />
          <span>Frigorífico Clay – Canelones</span>
        </li>
        <li>
          <img src={salto} alt="Frigorífico Salto" />
          <span>Frigorífico Salto - Salto</span>
        </li>
        <li>
          <img src={santaClara} alt="Abasto Santa Clara" />
          <span>Abasto Santa Clara – Montevideo</span>
        </li>
        <li>
          <img src={carrasco} alt="Frigorífico Carrasco" />
          <span>Frigorífico Carrasco – Montevideo</span>
        </li>
        <li>
          <img src={pul} alt="Frigorífico Pul – Melo" />
          <span>Frigorífico Pul – Melo</span>
        </li>
        <li>
          <img src={copayan} alt="Frigorífico Copayan" />
          <span>Frigorífico Copayan – Rocha</span>
        </li>
        <li>
          <img src={canelones} alt="Frigorífico Canelones" />
          <span>Frigorífico Canelones - Canelones</span>
        </li>
      </ListaDeclientes>
    </section>
  );
}

export default Clientes;
