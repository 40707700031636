import React from 'react';
import styled from 'styled-components';

import rieleras from '../Images/rieleras.jpg';
import rielerasEstructura from '../Images/rieleras-estructura.jpg';
import rielerasCambio from '../Images/rieleras-cambio.jpg';
import roscadas from '../Images/roscadas.jpg';
import incendios from '../Images/incendios.jpg';
import roldanas from '../Images/roldanas.jpg';
import aislacionesTermicas from '../Images/aislaciones-termicas.jpg';
import plegados from '../Images/plegados.jpg';
import cilindrados from '../Images/cilindrados.jpg';

const ListaDeServicios = styled.ul`
  li {
    display: inline-block;
    width: 16%;
    @media (max-width: 768px) {
      width: 29%;
    }
    @media (max-width: 425px) {
      width: 40%;
      margin: 5%;
    }
    margin: 2%;
    vertical-align: top;
    img {
      width: 100%;
      border-radius: 3px;
      border: 1px solid ${props => props.theme.colorPrincipal};
    }
    span {
      display: block;
      font-size: 0.9rem;
      font-weight: 400;
    }
  }
`;

function Servicios() {
  return (
    <section>
      <h2>Nuestros Servicios.</h2>
      <p>
        Metalúrgica FABAL concentra su especialidad en el montaje industrial,
        cañerías de alta presión, aire comprimido, NH3, vapor, trabajos en acero
        inoxidable, cañerías en acero inoxidable con atmósfera controlada, todo
        tipo de estructuras metálicas y soldaduras en general.
      </p>
      <ListaDeServicios>
        <li>
          <img
            src={rielerasEstructura}
            alt="Rieleras en frigorifoco"
            loading="lazy"
          />
          <span>
            Fabricación completa de estructura con rieleras para cámaras
            frigoríficas
          </span>
        </li>
        <li>
          <img src={rielerasCambio} alt="Vias de rieleras" loading="lazy" />
          <span>Cambios de vías para rieles frigoríficos.</span>
        </li>
        <li>
          <img src={rieleras} alt="Reemplezo de rieles" loading="lazy" />
          <span>
            Reemplazo parcial o total de rieles y cambios de vías en mal estado
            de cámaras en funcionamiento.
          </span>
        </li>
        <li>
          <img src={roscadas} alt="Cañerías roscadas" loading="lazy" />
          <span>
            Cañerías roscadas de agua a 90°, 45°, a temperatura ambiente.
          </span>
        </li>
        <li>
          <img src={incendios} alt="red de incendios" loading="lazy" />
          <span>
            Fabricación, instalación y distribución de red de incendios.
          </span>
        </li>
        <li>
          <img src={roldanas} alt="roldana" loading="lazy" />
          <span>
            Fabricación y reparación de roldanas calibradas en su peso.
          </span>
        </li>
        <li>
          <img
            src={aislacionesTermicas}
            alt="Aislaciones térmicas"
            loading="lazy"
          />
          <span>
            Aislaciones térmicas con o sin protección mecánica en líneas de agua
            caliente, vapor, NH3, etc.
          </span>
        </li>
        <li>
          <img src={plegados} alt="Plegados" loading="lazy" />
          <span>Plegados.</span>
        </li>
        <li>
          <img src={cilindrados} alt="Cilindrados" loading="lazy" />
          <span>Cilindrados.</span>
        </li>
      </ListaDeServicios>
    </section>
  );
}

export default Servicios;
