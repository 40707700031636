import React from "react";
import styled from "styled-components";
import isotipoBlanco from "../Images/isotipo-mf-colores.svg";

const IsotipoFabal = styled.img`
  width: 80px;
`;

const FooterWrapper = styled.footer`
  border-top: 1px solid ${props => props.theme.colorPrincipal};
  padding: 20px;
  text-align: center;
  p {
    margin: 0;
    font-size: 0.8rem;
    span {
      color: ${props => props.theme.colorPrincipal};
    }
  }
  a {
    font-size: 8px;
    color: white;
    text-decoration: none;
    padding: 3px 10px;
    border-radius: 20px;
    background-color: silver;
    margin: 20px 0;
    display: inline-block;
    &:hover {
      background-color: #4f00c1;
    }
  }
`;

function Footer() {
  return (
    <FooterWrapper>
      <IsotipoFabal src={isotipoBlanco} alt="Metalúrgica Fabál" />
      <p>
        <b>
          Metalúrgica <span>Fabál</span>
        </b>{" "}
        <br />
        Una empresa Uruguaya especializada en la prestación de servicios
        metalúrgicos.
      </p>
      <a href="https://warpvalley.com">WebApp by Warp Valley Inc.</a>
    </FooterWrapper>
  );
}

export default Footer;
