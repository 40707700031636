import React from "react";
import styled from "styled-components";

import BannerImg from "../Images/imagen-central.jpg";

const BannerPrincipal = styled.img`
  width: 100%;
  border-radius: 3px;
`;

function Empresa() {
  return (
    <div>
      <BannerPrincipal src={BannerImg} autoPlay loop></BannerPrincipal>
    </div>
  );
}

export default Empresa;
