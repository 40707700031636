import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import styled from "styled-components";

import Header from "./Components/Header";

import Empresa from "./Components/Empresa";
import Servicios from "./Components/Servicios";
import Galeria from "./Components/Galeria";
import Clientes from "./Components/Clientes";
import Contacto from "./Components/Contacto";
import Footer from "./Components/Footer";

const LightTheme = {
  colorPrincipal: "#e05f07",
  colorSecundario: "#5b5b5f",
  colorOscuro: "#191919"
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-size:1.1rem;
    font-weight:300;
    font-family: 'Open Sans', sans-serif;
    color: ${props => props.theme.colorOscuro};
  }
  h1,h2{
    font-weight:400;
    color: ${props => props.theme.colorPrincipal};
  }
  section{
    padding-bottom:20px;
  }
  ul{
    margin:0;
    padding:0;
    list-style-type:none;
  }
  a{
    color: ${props => props.theme.colorPrincipal};
    &:hover{
      text-decoration:none;
    }
  }

`;
const SiteWrapper = styled.main`
  max-width: 960px;
  padding: 20px;
  margin: auto;
`;

class App extends Component {
  render() {
    return (
      <ThemeProvider theme={LightTheme}>
        <Router>
          <SiteWrapper>
            <GlobalStyle />
            <Header />
            <Route path="/" exact component={Empresa} />
            <Route path="/servicios/" component={Servicios} />
            <Route path="/clientes/" component={Clientes} />
            <Route path="/galeria/" component={Galeria} />
            <Route
              path="/contacto/"
              render={props => <Contacto {...props} env={process.env} />}
            />
            <Footer />
          </SiteWrapper>
        </Router>
      </ThemeProvider>
    );
  }
}

export default App;
