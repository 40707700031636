import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import welding from "../Images/big-welding.jpg";

const FormContacto = styled.form`
  width: 50%;
  display: inline-block;
  vertical-align: top;
  @media (max-width: 768px) {
    width: 100%;
  }
  label {
    width: 100%;
    display: block;
    font-size: 0.8rem;
    height: 20px;
    color: ${props => props.theme.colorPrincipal};
  }
  input,
  textarea {
    border: 1px solid ${props => props.theme.colorSecundario};
    border-radius: 3px;
    padding: 10px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
  }
  button[type="submit"] {
    font-size: 1rem;
    color: white;
    padding: 10px 20px;
    background-color: ${props => props.theme.colorPrincipal};
    border: 1px solid ${props => props.theme.colorPrincipal};
    border-radius: 3px;
    &:hover {
      background-color: ${props => props.theme.colorSecundario};
    }
  }
  button[type="button"] {
    padding: 10px 20px;
    background-color: white;
    border: 1px solid white;
    border-radius: 3px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ImgSoldando = styled.img`
  width: 45%;
  margin-left: 5%;
  border-radius: 3px;
  display: inline-block;
  @media (max-width: 768px) {
    display: none;
  }
`;

class Contacto extends Component {
  state = {
    nombre: "",
    email: "",
    telefono: "",
    mensaje: "",
    formSubmitted: false,
    formEmailSent: ""
  };

  handleChangeNombre = this.handleChangeNombre.bind(this);
  handleChangeEmail = this.handleChangeEmail.bind(this);
  handleChangeTelefono = this.handleChangeTelefono.bind(this);
  handleChangeMensaje = this.handleChangeMensaje.bind(this);
  handleSubmit = this.handleSubmit.bind(this);
  handleReset = this.handleReset.bind(this);

  static sender = "web@metalurgicafabal.com.uy";

  handleChangeNombre(event) {
    this.setState({
      nombre: event.target.value
    });
  }
  handleChangeEmail(event) {
    this.setState({
      email: event.target.value
    });
  }
  handleChangeTelefono(event) {
    this.setState({
      telefono: event.target.value
    });
  }
  handleChangeMensaje(event) {
    this.setState({
      mensaje: event.target.value
    });
  }

  handleReset() {
    this.setState({
      nombre: "",
      email: "",
      telefono: "",
      mensaje: "",
      formSubmitted: false,
      formEmailSent: ""
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    event.target.disabled = true;
    const {
      REACT_APP_EMAILJS_RECEIVER: receiverEmail,
      REACT_APP_EMAILJS_TEMPLATEID: template
    } = this.props.env;

    this.sendContacto(
      template,
      this.props.senderEmail,
      receiverEmail,
      this.state.nombre,
      this.state.email,
      this.state.telefono,
      this.state.mensaje
    );

    this.setState({
      formSubmitted: true
    });
  }

  sendContacto(
    templateId,
    senderEmail,
    receiverEmail,
    nombre,
    email,
    telefono,
    mensaje
  ) {
    window.emailjs
      .send("mailgun", templateId, {
        senderEmail,
        receiverEmail,
        nombre,
        email,
        telefono,
        mensaje
      })
      .then(res => {
        this.setState({ formEmailSent: true });
      })
      // Handle errors here however you like, or use a React error boundary
      .catch(err => console.error("Failed to send feedback. Error: ", err));
  }
  render() {
    if (this.state.formEmailSent) {
      return (
        <section>
          <h2>Gracias por contactarse con Nosotros</h2>
          <p>Muy pronto nos pondremos en contacto con usted.</p>
          <button type="button" onClick={this.handleReset}>
            Volver
          </button>
        </section>
      );
    }
    return (
      <section>
        <h2>Contactese con Metalúrgica FABÁL</h2>
        <p>
          Complete el formulario a continuación o escribanos a{" "}
          <a href="mailto:info@metalurgicafabal.com.uy">
            info@metalurgicafabal.com.uy
          </a>{" "}
          para contactarse con nosotros. Tambien puede llamarnos al{" "}
          <a href="tel:099700976">+598 099 700 976</a> y con gusto atenderemos
          sus consultas.
        </p>
        <FormContacto onSubmit={this.handleSubmit}>
          <p>
            <label htmlFor="nombre">Nombre:</label>
            <input
              onChange={this.handleChangeNombre}
              type="text"
              id="nombre"
              value={this.state.nombre}
              required
            />
          </p>
          <p>
            <label htmlFor="email">E-Mail:</label>
            <input
              onChange={this.handleChangeEmail}
              type="text"
              id="email"
              value={this.state.email}
              required
            />
          </p>
          <p>
            <label htmlFor="telefono">Teléfono:</label>
            <input
              onChange={this.handleChangeTelefono}
              type="phone"
              id="telefono"
              value={this.state.telefono}
              required
            />
          </p>
          <p>
            <label htmlFor="mensaje">Mensaje:</label>
            <textarea
              onChange={this.handleChangeMensaje}
              id="mensaje"
              required
              value={this.state.mensaje}
              rows="4"
            />
          </p>

          {!this.state.formSubmitted ? (
            <p>
              <button type="submit">Enviar Mensaje</button>
              <button type="button" onClick={this.handleReset}>
                Borrar Formulario
              </button>
            </p>
          ) : (
            <div>Enviando Mensaje...</div>
          )}
        </FormContacto>
        <ImgSoldando src={welding} alt="Hombre Soldando" />
      </section>
    );
  }
}

Contacto.propTypes = {
  env: PropTypes.object.isRequired
};

export default Contacto;
