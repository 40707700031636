import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Menu } from "styled-icons/feather/Menu";

import LogoEmpresa from "../Images/metalurgica-fabal.svg";

const MenuIcon = styled(Menu)`
  color: ${props => props.theme.colorPrincipal};
`;

const MainHeader = styled.header`
  padding: 0 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const EmpresaLogo = styled.img`
  width: 200px;
  @media (max-width: 768px) {
    width: 150px;
  }
`;
const MainMenuContainer = styled.nav`
  button {
    display: none;
    border: 1px solid white;
    background-color: white;
    border-radius: 3px;
    &:hover {
      border: 1px solid ${props => props.theme.colorPrincipal};
    }
    @media (max-width: 768px) {
      display: block;
    }
  }
  ul {
    font-size: 0.8rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-radius: 3px;
    overflow: hidden;
    background-color: ${props => props.theme.colorPrincipal};
    @media (max-width: 768px) {
      display: none;
      position: absolute;
      width: 100%;
      top: 80px;
      bottom: 0;
      left: 0;
      font-size: 1rem;
      border-radius: 0px;
      z-index: 1;
    }
    li {
      display: inline-block;
      border-bottom: 0;
      @media (max-width: 768px) {
        text-align: center;
        display: block;
        border-bottom: 1px solid black;
      }
      a {
        display: block;
        padding: 15px 20px;
        text-transform: uppercase;
        text-decoration: none;
        color: white;
        background-color: ${props => props.theme.colorPrincipal};

        &:hover {
          background-color: black;
        }
      }
      a.active {
        background-color: ${props => props.theme.colorSecundario};
      }
    }
  }
`;

//codigo para mostrar siempre el menu principal aunque este
//oculto cuando la ventana llega a 769px de ancho
function getWindowSize() {
  let menu = document.querySelector(".menu-principal");
  if (document.documentElement.clientWidth >= 769) {
    menu.style.display = "block";
  } else {
    menu.style.display = "none";
  }
}
window.addEventListener("resize", getWindowSize);

//manejo visibilidad del boton del menu y del menu
function handleMostrarMenu() {
  let menu = document.querySelector(".menu-principal");
  let visibilidadMenuPrincipal = getComputedStyle(menu).display;
  visibilidadMenuPrincipal === "block"
    ? (menu.style.display = "none")
    : (menu.style.display = "block");
}
function handleMenuClick() {
  let menu = document.querySelector(".menu-principal");
  let boton = document.querySelector("#boton-menu-principal-mobile");
  const visibilidadBoton = getComputedStyle(boton).display;
  visibilidadBoton === "block"
    ? (menu.style.display = "none")
    : (menu.style.display = "block");
}

function Header() {
  return (
    <MainHeader>
      <NavLink exact to="/">
        <EmpresaLogo src={LogoEmpresa} alt="Metalurgica Fabal" />
      </NavLink>

      <MainMenuContainer>
        <button id="boton-menu-principal-mobile" onClick={handleMostrarMenu}>
          <MenuIcon size="40" />
        </button>
        <ul className="menu-principal">
          <li>
            <NavLink exact to="/" onClick={handleMenuClick}>
              Empresa
            </NavLink>
          </li>
          <li>
            <NavLink to="/servicios/" onClick={handleMenuClick}>
              Servicios
            </NavLink>
          </li>
          <li>
            <a href="/galeria/">Galeria</a>
          </li>
          {/*<li>
            <NavLink to="/clientes/" onClick={handleMenuClick}>
              Clientes
            </NavLink>
          </li>*/}
          <li>
            <NavLink to="/contacto/" onClick={handleMenuClick}>
              Contacto
            </NavLink>
          </li>
        </ul>
      </MainMenuContainer>
    </MainHeader>
  );
}

export default Header;
