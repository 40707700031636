import React, { Component } from "react";
import styled from "styled-components";

const GalleryOver = styled.article`
  background-color: white;
  position: absolute;
  height: 120px;
  width: 100%;
`;
const GalleyWarapper = styled.div`
padding-top:140px;
`;

class Galeria extends Component {

  render() {
    return (
      <section>
        <GalleryOver>
          <h2>Galería de Trabajos.</h2>
          <p>Estos son algunos de nuestros trabajos realizados.</p>
        </GalleryOver>

<GalleyWarapper id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></GalleyWarapper>

{
(function(){
var i, e, d = document, s = "script";i = d.createElement("script");i.async = 1;
i.src = "https://cdn.curator.io/published/31647581-1bbe-476a-a5bc-c59e8691861d.js";
e = d.getElementsByTagName(s)[0];e.parentNode.insertBefore(i, e);
})()
}

      </section>
    );
  }
}

export default Galeria;
